.ui.card.touch-stat-card {
  min-height: 250px;
  display: flex;
  align-content: center;
  flex: 1;
}

.ui.large.statistic {
  margin: auto;
}
